import { useContext } from 'react';
import { Route, Routes } from 'react-router';
import { DashboardLayout } from '../components/DashboardLayout';
import { routesConfig } from '../config/routes.config';
import { useSideBarConfig } from '../config/sidebar.config';
import UserContext from '../contexes/UserContext';
import { HomeScene } from './HomeScene';

export const DashboardRouter = () => {
  const [user] = useContext(UserContext);
  const nav = useSideBarConfig();

  return (
    <DashboardLayout homeUrl={routesConfig.DASHBOARD} user={user} nav={nav}>
      <Routes>
        <Route path={routesConfig.DASHBOARD} element={<HomeScene />} />
      </Routes>
    </DashboardLayout>
  );
};
