import { useEffect, useState } from 'react';
import { createForm, ReleoxForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { useFindVehicleQuery } from '../../generated/graphql';
import { faultInputs } from '../../inputs/fault.inputs';

// const form: FormInput[] = [faultInputs.description, faultInputs.photo, faultInputs.vehicleId];

// export const faultForm = createForm(form);

export type FaultFormBody = {
  description: string;
  photo: string;
  vehicleId: string;
};

export const useFaultForm = (): ReleoxForm | null => {
  const [form, setForm] = useState<ReleoxForm | null>(null);
  const { data } = useFindVehicleQuery();

  useEffect(() => {
    const fieldId = faultInputs.vehicleId;
    const f: FormInput[] = [faultInputs.description, faultInputs.photo];
    if (data?.vehicles) {
      fieldId.props.options = data?.vehicles.list.map((v) => ({
        value: v.id,
        label: `${v.registerNumber} (${v.VIN})`,
      }));
    }
    f.push(fieldId);
    setForm(createForm(f));
  }, [data]);

  return form;
};
