import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthButton } from 'shared-ui/lib/components/auth/AuthButton';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import login from 'shared-ui/lib/utils/login';
import { routesConfig } from '../../../config/routes.config';
import { loginForm } from '../../../form/login/login.form';
import { useLoginMutation } from '../../../generated/graphql';

export const LoginScene = () => {
  const { t } = useTranslation('LoginScene');
  const navigate = useNavigate();

  const [loginMutation, { loading }] = useLoginMutation({
    onCompleted: (data) => {
      if (data) {
        login(data.loginEmployee.token);
        navigate(routesConfig.DASHBOARD);
      }
    },
  });

  const handleLogin = useHandleSubmit(loginMutation);

  const FooterElement = (
    <>
      <div>
        <Link
          to={routesConfig.AUTH.FORGOT}
          className="font-medium  text-primary hover:text-secondary text-sm"
        >
          {t('forgotPassword')}
        </Link>
      </div>

      <AuthButton loading={loading} type="submit" label={t('login')} />
    </>
  );

  return (
    <AuthLayout>
      <AuthLogoTitle title={t('login')} />

      <AuthCard>
        <FormGen
          form={loginForm}
          onSubmit={handleLogin}
          wrapperClassNames="space-y-4"
          FooterElement={FooterElement}
        />
      </AuthCard>
    </AuthLayout>
  );
};
