import { HomeIcon, LogoutIcon } from '@heroicons/react/outline';
import { List } from 'immutable';
import { routesConfig } from './routes.config';

const sidebarConfig = [
  { name: 'home', href: routesConfig.DASHBOARD, icon: HomeIcon, current: false, exact: true },
];

type ListItem = {
  name: string;
  href: string;
  icon: typeof HomeIcon;
  current: boolean;
  preventActive?: boolean;
  exact?: boolean;
};

export const useSideBarConfig = () => {
  let list = List<ListItem>(sidebarConfig);

  list = list.push({
    name: 'logOut',
    href: routesConfig.AUTH.LOGOUT,
    icon: LogoutIcon,
    current: false,
  });

  return list.toArray();
};
