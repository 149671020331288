import _ from 'lodash';
import { sharedTranslations } from 'shared-ui/lib/shared-translations';
import ConfirmSceneTranslation from './scenes/auth/confirm/ConfirmSceneTranslation';
import ForgotSuccessSceneTranslation from './scenes/auth/forgot-success/ForgotSuccessSceneTranslation';
import ForgotSceneTranslation from './scenes/auth/forgot/ForgotSceneTranslation';
import LoginSceneTranslation from './scenes/auth/login/LoginSceneTranslation';
import ResetSuccessSceneTranslation from './scenes/auth/reset-success/ResetSuccessSceneTranslation';
import ResetPasswordSceneTranslation from './scenes/auth/reset/ResetPasswordSceneTranslation';
import HomeSceneTranslation from './scenes/HomeSceneTranslation';
import sidebarTranslation from './translations/sidebar.translation';

export default _.merge(
  sharedTranslations,
  HomeSceneTranslation,
  ResetSuccessSceneTranslation,
  ResetPasswordSceneTranslation,
  LoginSceneTranslation,
  ForgotSuccessSceneTranslation,
  ForgotSceneTranslation,
  ConfirmSceneTranslation,
  sidebarTranslation
);
