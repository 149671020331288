export default {
  fi: {
    LoginScene: {
      login: 'Employee kirjautuminen',
      areYouNew: 'Oletko uusi?',
      createAnAccount: 'Luo tunnus!',
      forgotPassword: 'Unohditko salasanasi?',
      dontHaveAnAccount: 'Eikö sinulla ole tunnuksia?',
      register: 'Rekisteröidy nyt!',
    },
  },
};
