import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { AuthButton } from 'shared-ui/lib/components/auth/AuthButton';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthErrorView } from 'shared-ui/lib/components/auth/AuthErrorView';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { useOnCompleteRedirect } from 'shared-ui/lib/hooks/apollo/use-on-complete-redirect';
import { ParseUrl } from 'shared-ui/lib/services/parse-url';
import { routesConfig } from '../../../config/routes.config';
import { resetPasswordForm } from '../../../form/reset-password/reset-password.form';
import {
  useResetPasswordMutation,
  useValidateResetTokenLazyQuery,
} from '../../../generated/graphql';

export const ResetPasswordScene = () => {
  const { t } = useTranslation('ResetPasswordScene');
  const [resetToken, setResetToken] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const location = useLocation();

  const [validateToken, { loading: isQueryLoading, error }] = useValidateResetTokenLazyQuery(
    setupQueryConfig()
  );

  useEffect(() => {
    (async () => {
      try {
        const token = ParseUrl.parseLocation(location, 'token');

        const response = await validateToken({
          variables: { body: { resetPasswordToken: token, type: 'business' } },
        });
        if (response.data?.validateResetToken) {
          setResetToken(token);
        } else {
          setShowError(true);
        }
      } catch (e) {
        setShowError(true);
      }
    })();
  }, [location, setShowError, validateToken]);

  const onComplete = useOnCompleteRedirect(routesConfig.AUTH.RESET_SUCCESS);

  const [resetPassword, { loading }] = useResetPasswordMutation(onComplete);

  const handleSubmit = useHandleSubmit(resetPassword, {
    body: {
      resetPasswordToken: resetToken,
      type: 'business',
    },
  });

  useEffect(() => {
    if (error) setShowError(true);
  }, [error]);

  if (showError)
    return <AuthErrorView loginRoute={routesConfig.AUTH.LOGIN} errorReason="Invalid token" />;
  if (isQueryLoading || !resetToken) return <AuthLoadingView />;

  return (
    <AuthLayout>
      <AuthLogoTitle title={t('changePassword')} />
      <AuthCard>
        <FormGen
          form={resetPasswordForm}
          onSubmit={handleSubmit}
          wrapperClassNames="space-y-4"
          FooterElement={<AuthButton loading={loading} type="submit" label={t('changePassword')} />}
        />
      </AuthCard>
    </AuthLayout>
  );
};
